/* .select_indicator::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 7px;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 2px;
  @apply bg-primary;
}

.menu-child {
  width: 200px;
  display: none;
  position: absolute;
  background-color: white;
  margin-top: 40px;
  padding: 10px 0px;
  -webkit-box-shadow: 0px 3px 5px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 5px 2px rgba(0, 0, 0, 0.1);
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s;
  top: 12px;
}

/* .menu-child {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  z-index: 1000;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
} */

/* .menu:hover + .menu-child {
  display: block;
}

.menu-child a {
  display: block;
  padding: 8px 0;
  color: #333;
  text-decoration: none;
} */

.menu-item {
  position: relative;
  display: flex;
}

.menu-child {
  width: 200px;
  display: none;
  position: absolute;
  background-color: white;
  margin-top: 10px;
  padding: 10px 0px;
  box-shadow: 0px 3px 5px 2px rgba(0, 0, 0, 0.1);
  transition: all linear 0.2s;
  left: 0;
  /* z-index: 100; */
  top: 30px;
}

.menu-item:hover .menu-child {
  display: block;
}

.menu-child a {
  display: block;
  padding: 8px 8px;
  color: #333;
  text-decoration: none;
}

.select_indicator::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 7px;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 2px;
  background-color: var(--primary-color, blue);
}
