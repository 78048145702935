/* Mega Menu */

.megamenu .nav-bar .nav .menu {
  position: static;
}

.megamenu .nav-bar .nav .menu .menuChild {
  display: none;
  flex-wrap: wrap;
  width: 100%;
  left: 0;
  right: 0;
  padding-bottom: 20px;
  visibility: visible;
  opacity: 1;
}

.megamenu .nav-bar .nav .menu .menuChild > li {
  width: 33.3%;
}

.megamenu .nav-bar .nav .menu > .menuChild > li > a {
  font-weight: bold;
}

.megamenu .nav-bar .nav .menu > .menuChild > li > ul a {
  padding-top: 2px;
  padding-bottom: 2px;
}

.megamenu .nav-bar .nav .menu:hover .menuChild {
  display: flex;
  height: inherit;
}

.megamenu .nav-bar .nav .menu .menuChild li ul {
  position: relative;
  display: block;
  left: 0;
  visibility: visible;
  opacity: 1;
  box-shadow: none;
  width: auto;
  background: transparent;
}

.megamenu .nav-bar .nav .menu .menuChild li a:hover,
.megamenu .nav-bar .nav a .menuChild a.active:hover {
  background: transparent;
}

/* Mega Menu */
.menu-child-list .menu-child-list {
  float: left;
  width: 190px;
}
.megamenu > .menu-child-list {
  @apply absolute shadow bg-white p-2 top-[38px] left-1/2 right-0 hidden w-screen -translate-x-1/2;
}
.megamenu:hover > .menu-child-list {
  display: block;
}
.menu-child-list.container {
  column-count: 4;
}
.menu-child-list.container > li {
  float: left;
  width: 190px;
  break-inside: avoid;
}
.menu-child-list.container > li > a {
  @apply uppercase font-bold;
}

.menu-child > li {
  border-right: 1px solid #ccc;
}

.menu-child ul li a {
  color: black;
  font-size: 12px;
  text-decoration: none;
}

.has-menu:hover .container {
  display: block !important;
}

.select-indicator::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 7px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 20px;
  height: 2px;
  background-color: #111;
}
