.category3_menu_main_header:hover > .category3_menu_child_icon {
  display: block;
}

.category3_menu_child_icon {
  display: none;
}

.active_link_3::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 1px;
  @apply bg-primary;
}
