.form_header {
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.form_header::before {
  content: "";
  height: 8px;
  width: 8px;
  display: block;
  border-radius: 100%;
  @apply bg-primary;
}

.footer_title::after {
  content: "";
  height: 4px;
  width: 70px;
  display: block;
  margin-top: 10px;
  border-radius: 5px;
  @apply bg-primary;
}
